import React, { Component } from "react";

import {
  TRUST_PILOT_BUSINESS_UNIT_ID,
  TRUST_PILOT_URL,
} from "@shared/constants";

// Copied from:
// https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-widget-to-a-single-page-application#add-a-trustbox-widget-with-a-react-application-1

const TrustBox = ({
  height = "24px",
  showTrustScore = true,
  trustBoxRef,
  width = "100%",
}: {
  height?: string;
  showTrustScore?: boolean;
  trustBoxRef: React.RefObject<HTMLDivElement>;
  width?: string;
}) => (
  <div
    ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
    className="trustpilot-widget"
    data-locale="en-US"
    data-template-id={
      showTrustScore ? "5419b732fbfb950b10de65e5" : "5406e65db0d04a09e042d5fc"
    }
    data-businessunit-id={TRUST_PILOT_BUSINESS_UNIT_ID}
    data-style-height={height}
    data-style-width={width}
  >
    <a href={TRUST_PILOT_URL} target="_blank" rel="noopener noreferrer">
      Trustpilot
    </a>
  </div>
);

interface TrustBoxContainerProps {
  height?: string;
  showTrustScore?: boolean;
  width?: string;
}

class TrustBoxContainer extends Component<TrustBoxContainerProps> {
  trustBoxRef: React.RefObject<HTMLDivElement>;
  constructor(props: TrustBoxContainerProps) {
    super(props);
    this.trustBoxRef = React.createRef();
  }
  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }
  render() {
    return (
      <TrustBox
        height={this.props.height}
        showTrustScore={this.props.showTrustScore}
        trustBoxRef={this.trustBoxRef}
        width={this.props.width}
      />
    );
  }
}
export default TrustBoxContainer;
